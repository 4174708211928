<style scoped lang="less">
.checked_tab {
  background: #fff;
  .stu-module-header {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }
  .content {
    padding: 0 20px 10px;
  }
}
</style>
<template>
  <div class="checked_tab">
    <div class="stu-module-header">
      <div class="stu-module-title">作业批阅</div>
      <el-button @click="back" type="primary">返回</el-button>
    </div>
    <el-tabs v-model="active" @tab-click="handleClick">
      <el-tab-pane label="未批阅列表" name="0"></el-tab-pane>
      <el-tab-pane label="已批阅列表" name="1"></el-tab-pane>
    </el-tabs>
    <div class="content">
      <el-form :model="form" label-width="80px" inline ref="checkForm">
        <el-form-item label="姓名/学号" prop="studentInfo">
          <el-input
            v-model="form.studentInfo"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="函授站" prop="siteId">
          <el-select v-model="form.siteId" clearable>
            <el-option
              v-for="(item, index) in siteOptions"
              :key="index"
              :label="item.siteName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级">
          <el-select v-model="form.gradeId" clearable>
            <el-option
              v-for="(item, index) in gradeOptions"
              :key="index"
              :label="item.gradeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业" prop="proId">
          <el-select v-model="form.proId" clearable>
            <el-option
              v-for="(item, index) in proOptions"
              :key="index"
              :label="item.profName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="层次" prop="stageId">
          <el-select v-model="form.stageId" clearable>
            <el-option
              v-for="(item, index) in stageOptions"
              :key="index"
              :label="item.stageName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="批阅人" prop="teacherId">
          <el-select v-model="form.teacherId" clearable>
            <el-option
              v-for="(item, index) in teacherOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提交时间" prop="submitTime">
          <el-date-picker
            v-model="submitTime"
            format="yyyy/MM/dd HH:mm:ss"
            value-format="yyyy/MM/dd HH:mm:ss"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div style="margin-bottom: 14px">
        <el-button @click="query(active)" type="primary">查询</el-button>
        <el-button @click="reset('checkForm')">重置</el-button>
        <el-button @click="rejected" type="primary">驳回</el-button>
      </div>
      <el-table
        :data="tableData"
        :height="height"
        v-loading="loading"
        @selection-change="selectionChange"
      >
        <el-table-column
          type="selection"
          align="center"
          width="70"
        ></el-table-column>
        <el-table-column label="序号" align="center" width="70">
          <template slot-scope="scope">
            <span>
              {{ scope.$index + (page.page - 1) * page.pageSize + 1 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(col, i) in columns"
          :key="i"
          :label="col.label"
          :prop="col.prop"
          :min-width="col.width"
          :formatter="col.formatter"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template slot-scope="scope">
            <el-button type="text" @click="details(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  IsReviewHomeWorkByWorkId,
  WithdrawCourseStudentWorkAnswer,
} from "@/libs/api/teacher/check";
import {
  EduGrade,
  EduStage,
  EduProfession,
  QueryEduTeacherByCourseId,
  QueryAllSite,
} from "@/libs/api/teacher/select";
export default {
  name: "checked_tab",
  data() {
    return {
      height: window.innerHeight - 500,
      loading: false,
      active: "0",
      submitTime: [],
      form: {
        studentInfo: null,
        siteId: null,
        gradeId: null,
        proId: null, // 专业id
        stageId: null, // 层次id
        teacherId: null, // 批阅人
      },
      siteOptions: [],
      gradeOptions: [],
      proOptions: [],
      stageOptions: [],
      teacherOptions: [],
      selectionData: [], // 选中数据
      tableData: [],
      columns: [
        { prop: "studentName", label: "姓名", width: 80 },
        { prop: "studentNo", label: "学号", width: 100 },
        { prop: "siteName", label: "函授站", width: 100 },
        { prop: "gradeName", label: "年级" },
        { prop: "profName", label: "专业", width: 120 },
        { prop: "stageName", label: "层次" },
        { prop: "score", label: "得分", sortable: true },
        { prop: "commitTime", label: "学生提交时间", width: 140 },
      ],
      total: 0,
      page: {
        page: 1,
        pageSize: 10,
      },
    };
  },
  activated() {
    const { o } = this.$route.query;
    if (o) {
      this.query(this.active);
    }
  },
  mounted() {
    this.selectInit();
    this.query(this.active);
  },
  methods: {
    query(typeStatus) {
      const rq = this.$route.query;
      let p = {
        ...this.page,
        ...this.form,
        ...rq,
        typeStatus,
      };
      if (this.submitTime.length) {
        p.startTime = this.submitTime[0];
        p.endTime = this.submitTime[1];
      }
      this.loading = true;
      IsReviewHomeWorkByWorkId(p)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectInit() {
      // 站点
      QueryAllSite({ isAll: 1 }).then((res) => {
        this.siteOptions = res.data;
      });
      let data = new FormData();
      EduGrade(data).then((res) => {
        this.gradeOptions = res.data;
      });
      EduStage(data).then((res) => {
        this.stageOptions = res.data;
      });
      EduProfession(data).then((res) => {
        this.proOptions = res.data;
      });
      // 批阅人
      const courseId = this.$route.query.courseId;
      QueryEduTeacherByCourseId({ courseId }).then((res) => {
        this.teacherOptions = res.data;
      });
      EduProfession;
    },
    reset(refName) {
      this.$refs[refName].resetFields();
      this.submitTime = [];
    },
    rejected() {
      if (!this.selectionData.length)
        return this.$message.error("请至少选择一条数据");

      const workSetId = this.$route.query.workSetId;
      WithdrawCourseStudentWorkAnswer({
        workSetId, //作业id
        workRecordIds: this.selectionData.map((m) => m.id).join(), //作业记录id
      }).then((res) => {});
    },
    handleClick(tab) {
      const name = tab.name;
      this.query(name);
    },
    selectionChange(val) {
      this.selectionData = val;
    },
    details(row) {
      if (row) {
        const rq = this.$route.query;
        const { id, studentId } = row;
        this.$router.push({
          path: "list/info",
          query: { id, studentId, ...rq, active: this.active },
        });
      }
    },
    back() {
      this.$router.push("/teacher/checkWork");
    },
    handleCurrentChange(page) {
      if (page) {
        this.page.page = page;
        this.query(this.active);
      }
    },
  },
};
</script>